import React, { useState, useEffect, useRef } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";

import client from "../instances/contentful";
import Layout from "../components/Layout";
import Container from "../components/Container";
import Title from "../components/Title";
import Hero from "../components/indexComponents/Hero";
import SobreNos from "../components/indexComponents/SobreNos";
import Servicos from "../components/indexComponents/Servicos";
import Estrutura from "../components/indexComponents/Estrutura";
import Galeria from "../components/indexComponents/Galeria";
import Contato from "../components/indexComponents/Contato";

const Index = (props) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  useEffect(() => {
    client
      .getEntry("6RYgXyOS2u2dCnDKdsh5Gj", { locale: "en-US" })
      .then((entry) => {
        setData(entry.fields);
        console.log(entry.fields);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  // if (loading) return "Loading...";
  return (
    <Layout language="en">
      <Container>
        <Hero language="en" data={data} />
        <SobreNos language="en" data={data} />
        <Servicos language="en" data={data} />
        <Estrutura language="en" data={data} />
        <Galeria language="en" data={data} />
        <Contato language="en" data={data} />
      </Container>
    </Layout>
  );
};

export default Index;

// export const pageQuery = graphql`
//   query HomeQuery {
//     allContentfulHome {
//       edges {
//         node {
//           id
//           childContentfulHomeDescrioDeAberturaTextNode {
//             id
//             descrioDeAbertura
//           }
//         }
//       }
//     }
//   }
// `;
